.login_page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    form {
        display: flex;
        flex-direction: column;
        width: 21.5%;
        background-color: #fff;
        padding: 2rem;
        border-radius: 0.2rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        @media (max-width: 600px) {
            width: 80%;
        }
        @media (min-width: 1400px){
            width: 21.5%;
         }
        h2{
            font-size: 0.9rem;
            font-weight: 500;
            color:#566a7f;
            text-align: left;
        }
        p{
            color: #566a7f;
        }
        input {
            display: block;
            width: 100%;
            padding: 0.4375rem 0.875rem;
            font-size: 0.9375rem;
            font-weight: 400;
            line-height: 1.53;
            color: #697a8d;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #d9dee3;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0.375rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
        .otp_btn_disable{
            background-color: #ccc;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            color: #fff;
        }
        .otp_btn{
            background-color: #696cff;
            border-color: #696cff;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, 0.4);
            color: #fff;
            border: none;
            padding: 5px 10px !important;
            border-radius: 4px;
        }
        .otp_btn:hover{
            background-color: #6610f2;
        }
        .signin_btn {
            margin: 0 auto;
            color: #fff;
            background-color: #696cff;
            border-color: #696cff;
            box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, 0.4);
            width: 100% !important;
        }
        .signin_btn:hover {
            background-color: #6610f2;
        }
        .signin_btn_disabled {
            width: 100%;
            margin: 0 auto;
            color: #fff;
            background-color: #ccc;
        }
    }

}